<template>
  <b-card
    no-body
    class="p-2"
  >
    <h3 class="mb-2">
      {{
        academicTraining.formacionAcademicaId == 0
          ? $t("Routes.AcademicTrainingAdd")
          : $t("Routes.AcademicTrainingEdit")
      }}
    </h3>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- name work area field -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.academicTraining.columns.name')"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.academicTraining.columns.name')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-input v-model="academicTraining.nombre" />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Description Field -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required"
            >
              <b-form-group
                :label="
                  $t('humanResources.academicTraining.columns.description')
                "
                :state="errors.length > 0 ? false : null"
              >
                <b-form-textarea v-model="academicTraining.descripcion" />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="float-right mt-2 mb-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            <b-spinner v-if="addingAcademicTraining" small class="mr-1" />
            {{
              academicTraining.formacionAcademicaId == 0
                ? $t("Lists.Add")
                : $t("Lists.Edit")
            }}
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,

  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import humanResources from '@/services/humanresources.service'
import { ref } from '@vue/composition-api'
import router from '@/router'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormInvalidFeedback,
    BFormGroup,
    BFormTextarea,
    BButton,

    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const addingAcademicTraining = ref(false)
    const academicTraining = ref({
      formacionAcademicaId: 0,
      nombre: '',
      descripcion: '',
      archivado: false,
    })

    const blankAcademicTraining = {
      formacionAcademicaId: 0,
      nombre: '',
      descripcion: '',
      archivado: false,
    }

    const {
      fetchAcademicTraining,
      createAcademicTraining,
      updateAcademicTraining,
    } = humanResources()

    const resetAcademicTraining = () => {
      academicTraining.value = JSON.parse(JSON.stringify(blankAcademicTraining))
    }

    if (router.currentRoute.params.formacionAcademicaId) {
      fetchAcademicTraining(router.currentRoute.params.formacionAcademicaId, data => {
        academicTraining.value = data
      })
    } else {
      academicTraining.value = JSON.parse(JSON.stringify(blankAcademicTraining))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetAcademicTraining)

    const onSubmit = () => {
      addingAcademicTraining.value = true
      if (academicTraining.value.formacionAcademicaId === 0) {
        createAcademicTraining(academicTraining.value, () => {
          addingAcademicTraining.value = false
          router.push({ name: 'apps-human-resources-academic-training-list' })
        })
      } else {
        updateAcademicTraining(academicTraining.value.formacionAcademicaId, academicTraining.value, () => {
          addingAcademicTraining.value = false
          router.push({ name: 'apps-human-resources-academic-training-list' })
        })
      }
    }

    return {
      academicTraining,
      addingAcademicTraining,
      required,
      getValidationState,
      refFormObserver,
      resetForm,
      onSubmit,

    }
  },
}
</script>
